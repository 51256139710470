<template>
	<!-- 账户列表 -->
	<div class="bullMarket-account">
		<div class="el-content mb12">
			<a-space>
				<a-range-picker v-model:value="search.time" />
				<a-input-search style="width: 400px;"
					v-model:value="search.key" 
					placeholder="请输入查询关键字" 
					enter-button 
					@search="getBullAccountList(1,info.limit)" />
			</a-space>
		</div>
		
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'账户ID',dataIndex:'account_id'},
				{title:'用户信息',dataIndex:'name',slots:{customRender:'name'}},
				{title:'账号信息',dataIndex:'account',slots:{customRender:'account'}},
				{title:'证件信息',dataIndex:'cert',slots:{customRender:'cert'}},
				{title:'银行信息',dataIndex:'bank',slots:{customRender:'bank'}},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #name="{record}">
					<div style="display: flex;" v-if="record.account">
						<a-avatar size="medium" :src="record.account.avatar_url"/>
						<div style="margin-left: 4px;">
							<div><span>{{record.account.nickname}}</span></div>
							<div><span class="f12">user_id：</span>{{record.account.user_id}}</div>
						</div>
					</div>
				</template>
				<template #account="{record}">
					<div>姓名：{{record.name}}</div>
					<div>拼音：{{record.name_py}}</div>
					<div>电话：{{record.mobile}}</div>
				</template>
				<template #cert="{record}">
					<div>证件号码：{{record.id_no}}</div>
					<div>证件类型：身份证</div>
					<div>证件有效期：{{record.id_expired_at}}</div>
				</template>
				<template #bank="{record}">
					<div><span class="f12">银行名称：</span>{{record.bank_name}}</div>
					<div><span class="f12">银行卡号：</span>{{record.bank_sn}}</div>
					<div><span class="f12">银行网点：</span>{{record.online_shop}}</div>
				</template>
				<template #status="{record}">
					<div class="mb12">
						<span class="f12">创建ceb电子账户：</span>
						<a-tag :color="record.is_ceb_account == 1 ?'#00CC66':'#FF9900'">{{record.is_ceb_account ? '已创建':'未创建'}}</a-tag>
					</div>
					<div class="mb12">
						<span class="f12">创建ceb电子账户查证：</span>
						<a-tag :color="record.is_ceb_account_check == 1 ?'#00CC66':'#FF9900'">{{record.is_ceb_account_check ? '已查证':'未查证'}}</a-tag>
					</div>
					<div class="mb12">
						<span class="f12">Ceb提交身份证：</span>
						<a-tag :color="record.is_ceb_account_real == 1 ?'#00CC66':'#FF9900'">{{record.is_ceb_account_real ? '已提交':'未提交'}}</a-tag>
					</div>
										
					<div class="mb12">
						<span class="f12">关联FCS系统账户：</span>
						<a-tag :color="record.is_ceb_fcs == 1 ?'#00CC66':'#FF9900'">{{record.is_ceb_fcs ? '已关联':'未关联'}}</a-tag>
					</div>
					<div>
						<span class="f12">开通状态：</span>
						<a-tag color="#999" v-if="record.open_status==0">未开通</a-tag>
						<a-tag color="#FF9900" v-if="record.open_status==1">处理中</a-tag>
						<a-tag color="#00CC66" v-if="record.open_status==2">已开通</a-tag>
						<a-tag color="#FF0066" v-if="record.open_status==3">开通失败</a-tag>
					</div>
				</template>
				<template #action="{record}">
					<router-link :to="{path:'/adhibition/bullMarket/supervise',query:{re_id:record.user_id,re_ty:'create_user'}}">
						<a-button type="primary" size="small">关联</a-button>
					</router-link>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBullAccountList(info.page,e)}"
					@change="(e)=>{getBullAccountList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import bullModel from '@/api/addons/bull'
export default{
	setup(){
		const state = reactive({
			search:{
				key:'',
				time:[],
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			}
		})
		getBullAccountList(1,state.info.limit)
		function getBullAccountList(page,limit){
			bullModel.getBullAccount(page,limit,state.search,res=>state.info = {limit,...res})
		}
		return{
			...toRefs(state),
			getBullAccountList
		}
	}
}
</script>

<style>
</style>
