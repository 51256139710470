// 牛市插件
import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import router from '@/router'

class Bull{

    /**
     * 获取牛市分类列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getBullCategory(page,limit,extra,fn){
        let param = {page,limit}
        if( extra && extra.key ) param.key = extra.key
        common.getDataList("getDwCategoryList",param).then(res=>fn(res))
    }

    /**
     * 新增或编辑牛市分类信息
     * @param {number} param 
     * @param {function} fn 
     */
    addOrEditCate(param,fn){
        if( !param.id ) delete param.id
        $post("setDwCategory",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 删除牛市分类
     * @param {number} id 
     * @param {function} fn 
     */
    deleteBullCate(id,fn){
        tool.confirm("确认删除分类信息吗?").then(res=>{
            $post('delDwCategory',{id}).then(res=>{
                tool.message('删除成功')
                fn(res.data)
            })
        })
    }
    
    /**
     * 获取牛市列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getBull(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.key ) param.key = extra.key
        common.getDataList("getDwAnimalList",param).then(res=>fn(res))
    }

    /**
     * 获取牛市畜牧详情
     * @param {number} id 
     * @param {Array} keys 需要的字段
     * @param {function} fn 
     */
    getBullDetail(id,keys=null,fn){
        $post('getDwAnimalDetail',{id}).then(res=>{
            if(keys ){
                fn(tool.filterForm(keys,res.data))
                return
            }
            fn(res.data)
        })
    }

    /**
     * 新增或编辑畜牧信息
     * @param {object} param 
     */
    addOrEditBull(param){
        if( !param.id ) delete param.id
        $post("setDwAnimal",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 删除牛市信息
     * @param {number} id 
     * @param {function} fn 
     */
    delBull(id,fn){
        tool.confirm("确认删除畜牧信息吗?").then(()=>{
            $post('delDwAnimal',{id}).then(res=>{
                tool.message('删除成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取牛市订单信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getBullOrder(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.time.length > 0 ){
                param.start_at = moment(extra.time[0],"YYYY-MM-DD")
                param.end_at = moment(extra.time[1],"YYYY-MM-DD")
            }
            if( extra.status == 1 ){
                param.pay_status = 0
                param.is_cancel = 0
                param.is_refund = 0
            }
            if( extra.status == 2 ){
                param.pay_status = 1
                param.is_cancel = 0
                param.is_refund = 0
            }
            
            if( extra.status == 3 ) param.is_cancel = 1
            if( extra.status == 4 ) param.is_confirm = 1
            if( extra.status == 5 ) param.is_refund = 1
            if( extra.fcs_pay_status != '' ) param.fcs_pay_status = extra.fcs_pay_status
        }
        common.getDataList("getDwOrderList",param).then(res=>fn(res)) 
    }

    /**
     * 牛市订单重试操作
     * @param {number} id 
     * @param {function} fn 
     */
    bullOrderReTry(id,fn){
        tool.confirm("确认进行重试操作吗?").then(()=>{
            $post('setDwOrderReset',{id}).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取关联设备
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getCebReqList(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.re_ty ) param.re_ty = extra.re_ty
            if( extra.re_id ) param.re_id = extra.re_id
            if( extra.sys ) param.sys = extra.sys
        }
        common.getDataList("getCebReqList",param).then(res=>fn(res))
    }

    /**
     * 获取商家列表
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getBullMerchant(page,limit,fn){
        let param = { page,limit}
        common.getDataList("getDwShopList",param).then(res=>fn(res))
    }

    /**
     * 获取充值列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getBullRecharge(page,limit,extra,fn){
        let param = { page,limit}
        if( extra.tr_id !='') param.tr_id = extra.tr_id
        if( extra.option_status !='') param.option_status = extra.option_status
        if( extra.tr_status !='') param.tr_status = extra.tr_status
        if( extra.tr_check_status !='') param.tr_check_status = extra.tr_check_status
        if( extra.tr2_status !='') param.tr2_status = extra.tr2_status
        if( extra.tr2_check_status !='') param.tr2_check_status = extra.tr2_check_status
        common.getDataList("getCebRechargeList",param).then(res=>fn(res))
    }

    /**
     * 充值重试操作
     * @param {number} id 
     * @param {function} fn 
     */
    bullRechargeReTry(id,fn){
        tool.confirm("确认进行重试操作吗?").then(()=>{
            $post('setCebRechargeReset',{id}).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取电子账户列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getBullAccount(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.time.length > 0 ){
                param.start_at = moment(extra.time[0],'YYYY-MM-DD')
                param.end_at = moment(extra.time[1],'YYYY-MM-DD')
            }
        }
        common.getDataList("getDwAccountList",param).then(res=>fn(res))
    }

    /**
     * 获取提现列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getBullWithdraw(page,limit,extra,fn){
        let param = { page,limit}
        if( extra.tr_id !='') param.tr_id = extra.tr_id
        if( extra.option_status !='') param.option_status = extra.option_status
        if( extra.tr_status !='') param.tr_status = extra.tr_status
        if( extra.tr_status_check !='') param.tr_status_check = extra.tr_status_check
        if( extra.tr2_status !='') param.tr2_status = extra.tr2_status
        if( extra.tr2_status_check !='') param.tr2_status_check = extra.tr2_status_check
        common.getDataList("getCebWithdrawalList",param).then(res=>fn(res))
    }

    /**
     * 提现操作
     * @param {string} type agree同意提现 deny 拒绝提现
     * @param {object} param 
     */
    handleBullWithdraw(type,param){
        if( type == 'agree' ){
            tool.confirm("确认同意该提现操作吗？").then(()=>{
                $post('setCebWithdrawalAgree',param).then(res=>{
                    tool.message('操作成功')
                    fn(res.data)
                })
            }).catch(()=>{})
            return
        }

        if( type == 'deny'){
            if( !tool.returnMessage(param.desc,"请填写拒绝理由")) return
            $post('setCebWithdrawalRefuse',param).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
            return
        }
    }

    /**
     * 提现操作提示
     * @param {number} id 
     * @param {object} fn 
     */
    bullWithdrawReTry(id,fn){
        tool.confirm("确认进行重试操作吗").then(()=>{
            $post('setCebWithdrawalReset',{id}).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }
}

const bullModel = new Bull()
export default bullModel
